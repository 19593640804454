
import { Component, Vue } from "vue-property-decorator";
import { signUpService } from "@/services/api/sign-up.service";

@Component
export default class VerifyEmailByLink extends Vue {
  async mounted(): Promise<void> {
    let token = this.$route.query.guid;
    if (token == null) {
      this.$router.push({ path: "/login" });
    } else {
      await signUpService.ConfirmUser(this.$route.query.guid.toString());
    }
  }

  private nextStep() {
    this.$router.push({
      name: "Login",
      params: {
        next: "step3",
      },
    });
  }
}
