import { UserSignUp } from "@/models/entities/user-signup.interface";
import { ApiService } from "@zelando/vuelando";

const controller = "signup";

class SignUpService extends ApiService {
  constructor() {
    super(process.env.VUE_APP_API_ENDPOINT);
  }

  SignUp(userSignUp: UserSignUp) {
    return this.post<UserSignUp, void>(`${controller}/signup`, userSignUp);
  }

  ConfirmUser(token: string) {
    return this.post<string, void>(
      `${controller}/confirmuser?token=${token}`,
      ""
    );
  }

  SendConfirmMail(email: string) {
    return this.getAll<string>(`${controller}/sendconfirmemail?email=${email}`);
  }
}

export const signUpService = new SignUpService();
